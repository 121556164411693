import PropTypes from 'prop-types';
import React from 'react';
import { withNamespaces } from 'react-i18next';
import Ajax from '../../common/ajax';
import Modal from '../../common/containers/Modal';
import Config from '../../config';

class TemplateCustomAttributesButton extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      attributes: null,
      showModal: false,
    };
  }

  componentDidMount() {
    this.fetchCustomizableAttributes();
  }

  fetchCustomizableAttributes() {
    const { template } = this.props;
    const url = `${Config.apiHost}templates/${template.id}/customizable_attributes/`;
    Ajax.get(url).done((attributes) => {
      this.setState((state) => ({ ...state, attributes }));
    });
  }

  render() {
    const { i18n } = this.props;
    const { attributes } = this.state;

    return (
      <>
        {attributes && attributes.length > 0 && (
          <>
            <a
              role="button"
              onClick={() =>
                this.setState((state) => ({ ...state, showModal: true }))
              }
            >
              {i18n.t(
                'Clique para ver alguns dos atributos personalizáveis desse modelo'
              )}
            </a>

            <Modal
              title={i18n.t(
                'Alguns dos atributos personalizáveis desse modelo'
              )}
              show={this.state.showModal}
              width="large"
              onCancel={() =>
                this.setState((state) => ({ ...state, showModal: false }))
              }
            >
              <table className="table">
                <thead>
                  <tr>
                    <th>Attribute</th>
                    <th>Description</th>
                  </tr>
                </thead>
                <tbody>
                  {attributes.map((attr, idx) => {
                    return (
                      <tr key={idx}>
                        <td>{attr.label}</td>
                        <td className="text-muted">
                          {attr.help_text && attr.help_text}
                          {!attr.help_text &&
                            `${i18n.t('Define o(a)')} ${attr.label} ${i18n.t(
                              'do documento'
                            )}`}
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
              <p>
                {i18n.t(
                  'Caso esses atributos não sejam suficientes para as suas necessidades, entre em contato com nosso suporte para verificarmos a possibilidade de inserir novos atributos.'
                )}
              </p>
            </Modal>
          </>
        )}
      </>
    );
  }
}

TemplateCustomAttributesButton.propTypes = {
  template: PropTypes.object.isRequired,
};

export default withNamespaces()(TemplateCustomAttributesButton);
