import React from 'react';
import PropTypes from 'prop-types';
import { withNamespaces } from 'react-i18next';

class ABNTNorms extends React.Component {
  render() {
    const { template, i18n } = this.props;
    return (
      <>
        {template.is_abnt && template.is_book && (
          <>
            <h3>{i18n.t('Normas atendidas')}</h3>
            <p>
              NBR 6023:2018: Informação e documentação - Referência -
              Elaboração; NBR 6024:2012: Informação e documentação - Numeração
              progressiva das seções de um documento - Apresentação; NBR
              6027:2012: Informação e documentação - Sumário - Apresentação; NBR
              6028:2003: Informação e documentação - Resumo - Apresentação; NBR
              6029:2006: Informação e documentação - Livros e folhetos -
              Apresentação; NBR 6034:2004: Informação e documentação - Índice -
              Apresentação; NBR 10520:2002: Informação e documentação -
              Citações; NBR 10719:2011: Informação e documentação - Relatório
              técnico e/ou científico - Apresentação; NBR 14724:2011: Informação
              e documentação - Trabalhos acadêmicos - Apresentação; NBR
              15287:2011: Informação e documentação - Projeto de pesquisa -
              Apresentação;
            </p>
          </>
        )}
        {template.is_abnt && template.is_article && (
          <>
            <h3>{i18n.t('Normas atendidas')}</h3>
            <p>
              NBR 6022:2003: Informação e documentação - Artigo em publicação
              periódica científica impressa - Apresentação; NBR 6023:2018:
              Informação e documentação - Referência - Elaboração; NBR
              6024:2012: Informação e documentação - Numeração progressiva das
              seções de um documento - Apresentação; NBR 6028:2003: Informação e
              documentação - Resumo - Apresentação; NBR 10520:2002: Informação e
              documentação - Citações;
            </p>
          </>
        )}
      </>
    );
  }
}

ABNTNorms.propTypes = {
  template: PropTypes.object.isRequired,
};

export default withNamespaces()(ABNTNorms);
