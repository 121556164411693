import React, {Component} from 'react';
import PropTypes from 'prop-types';
import LocaleUtils from "../../common/LocaleUtils";
import {withNamespaces} from "react-i18next";

class UsageCount extends Component {
    render() {
        const {i18n} = this.props;
        return (
            <span>
                <i className="mdi mdi-fire text-danger" />
                {' '}
                {i18n.t('Utilizado por mais de')} {LocaleUtils.number(this.props.template.count)}
                {' '}
                {i18n.t('pessoas')}
            </span>
        );
    }
}

UsageCount.propTypes = {
    template: PropTypes.object.isRequired,
};

export default withNamespaces()(UsageCount);
