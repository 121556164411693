import React from 'react';
import Content from '../common/containers/Content';
import PublicPage from '../common/containers/PublicPage';
import TemplateList from './TemplateList';
import ErrorBoundary from '../common/containers/ErrorBoundary';
import { withNamespaces } from 'react-i18next';

class TemplateListPage extends React.Component {
  render() {
    const { i18n } = this.props;
    window.document.title = 'FastFormat';

    return (
      <PublicPage title={i18n.t('Modelos')} hasAside={false}>
        <Content>
          <ErrorBoundary>
            <TemplateList alias={this.props.match.params.alias} />
          </ErrorBoundary>
        </Content>
      </PublicPage>
    );
  }
}

export default withNamespaces()(TemplateListPage);
