import React from 'react';
import PropTypes from 'prop-types';
import Button from '../../common/components/Button';
import Ajax from '../../common/ajax';
import Config from '../../config';
import URLS from '../../urls';
import { withNamespaces } from 'react-i18next';

class NewDocumentButton extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      processing: false,
      redirectToDocument: null,
    };
    this.onClick = this.onClick.bind(this);
  }

  onClick() {
    this.setState({
      ...this.state,
      processing: true,
    });
    const url = `${Config.apiHost}templates/${this.props.template.id}/new_document/`;
    Ajax.post(url)
      .done((response) => {
        this.setState({
          processing: false,
          redirectToDocument: response.document_id,
        });
      })
      .fail((jqXHR) => {
        if (jqXHR.status === 403) {
          document.location = `${Config.djangoHost}accounts/login`;
        }
      });
  }

  render() {
    if (this.state.redirectToDocument) {
      document.location = URLS.documentEdit(this.state.redirectToDocument);
    }

    const { i18n, template } = this.props;

    return (
      <Button
        {...this.props}
        processing={this.state.processing}
        type="primary"
        title={i18n.t('Criar novo documento com esse modelo')}
        onClick={this.onClick}
      >
        {template.type === 'presentation' ? i18n.t('Criar apresentação') : i18n.t('Criar documento')}
      </Button>
    );
  }
}

NewDocumentButton.propTypes = {
  ...Button.propTypes,
  template: PropTypes.object.isRequired,
};

export default withNamespaces()(NewDocumentButton);
