import React, { Component } from 'react';
import { withNamespaces } from 'react-i18next';
import Modal from '../../common/containers/Modal';
import FreshDesk from '../../integrations/FreshDesk';
import URLS from '../../urls';

class TemplateRequest extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showModal: false,
    };
  }

  render() {
    const { i18n } = this.props;
    return (
      <>
        <p>
          {i18n.t(
            'Não encontrou seu modelo? Experimente utilizar um de nossos'
          )}{' '}
          <a href={`${URLS.modelos}?venue=generic`}>
            {i18n.t('modelos genéricos')}
          </a>
          .
          <br />
          {i18n.t(
            'Caso os modelos genéricos não atendam suas necessidades, você também pode'
          )}{' '}
          <a
            role="button"
            onClick={() => this.setState((s) => ({ ...s, showModal: true }))}
          >
            {i18n.t('solicitar um novo modelo')}
          </a>{' '}
          {i18n.t('sem custos')}.
        </p>
        <Modal
          title={i18n.t('Solicitação de modelo')}
          show={this.state.showModal}
          onCancel={() =>
            this.setState((state) => ({ ...state, showModal: false }))
          }
        >
          <h4>
            {i18n.t(
              'É possível solicitar novos modelos entrando em contato com nosso suporte.'
            )}
          </h4>

          <h4>
            {i18n.t(
              'Ao solicitar um novo modelo, certifique-se de incluir o link para o site da instituição contendo as normas a serem seguidas.'
            )}
          </h4>
          <h4>
            {i18n.t(
              'Entretanto, o FastFormat se reserva o direito de analisar a viabilidade de fazer o novo modelo, podendo a solicitação ser declinada ou aceita.'
            )}
          </h4>

          <h4>
            <FreshDesk showIcon={false} />
          </h4>
        </Modal>
      </>
    );
  }
}

export default withNamespaces()(TemplateRequest);
