import PropTypes from 'prop-types';
import React from 'react';
import { withNamespaces } from 'react-i18next';
import Col from '../../common/containers/Col';
import Panel from '../../common/containers/Panel';
import Row from '../../common/containers/Row';
import Config from '../../config';
import Institution from '../../emec/components/Institution';
import Testimonials from '../../sales/plans/Testimonials';
import TwitterTestimonials from '../../sales/plans/TwitterTestimonials';
import ABNTNorms from './ABNTNorms';
import HowToUse from './HowToUse';
import NewDocumentButton from './NewDocumentButton';
import TemplateCustomAttributes from './TemplateCustomAttributes';
import UsageCount from './UsageCount';

class TemplateDetail extends React.Component {
  renderDescription() {
    const { template, i18n } = this.props;
    const { language = 'pt' } = i18n;
    return (
      <>
        <h4>
          {language.startsWith('pt') && (
            <div
              dangerouslySetInnerHTML={{
                __html: template.description_pt_br,
              }}
            />
          )}
          {!language.startsWith('pt') && (
            <div
              dangerouslySetInnerHTML={{
                __html: template.description_us_en,
              }}
            />
          )}
        </h4>

        <h4>
          {i18n.t('Os modelos do FastFormat são altamente personalizáveis.')}{' '}
          {i18n.t(
            'A depender do modelo escolhido, você poderá utilizá-lo para Trabalhos de Conclusão de Curso (TCC), dissertações de mestrado, teses de doutorado, livros, artigos científicos, ou qualquer outro tipo de documento que você ache adequado.',
          )}
        </h4>
        <h4>
          {i18n.t(
            'Por exemplo, em muitos casos você poderá escolher o estilo de citações e referências (como ABNT, ACM, APA, Chicago, Harvard, IEEE, MLA, NP 405 e Vancouver), margens, posicionamento de legendas, tipo de impressão, tipo e tamanho da fonte, dentre vários outros que podem variar de modelo para modelo.',
          )}{' '}
          <TemplateCustomAttributes template={template} />.
        </h4>
        <h4>
          {i18n.t(
            'Os atributos personalizáveis de cada modelo vêm pré-configurados com os valores definidos nas normas aplicáveis ao mesmo, ou com valores normalmente aceitos pela comunidade acadêmica.',
          )}{' '}
          {i18n.t(
            'No entando, é recomendável sempre verificar as normas de sua instituição para confirmar se o modelo realmente atende as necessidades.',
          )}
        </h4>
      </>
    );
  }

  render() {
    const { i18n, template } = this.props;
    const { language = 'pt' } = i18n;
    window.document.title = `FastFormat - ${template.name}`;

    return (
      <>
        <Panel>
          <Row>
            <Col md={10}>
              <h2>{template.name}</h2>
            </Col>
            <Col md={2}>
              <NewDocumentButton template={template} size="lg" block={true} style={{ marginTop: '12px' }} />
            </Col>
          </Row>
          <hr />
          <Row>
            <Col md={5}>
              <div className="thumbnail">
                <img
                  src={`${Config.djangoHost}static/${template.thumbnail}?${template.thumbnail_hash}`}
                  alt={template.name}
                  title={template.name}
                />
              </div>
            </Col>
            <Col md={7}>
              {!template.real_template_id && this.renderDescription()}

              {template.real_template_id && (
                <p className="text-info">
                  <i className="mdi mdi-info" />{' '}
                  {i18n.t(
                    'Aviso! Esse é um template genérico que segue as normas da ABNT e que, provavelmente, atenderá os requisitos da instituição. Caso não atenda, entre em contato com nosso suporte para verificarmos a possibilidade de fazer um modelo específico para essa instituição.',
                  )}
                </p>
              )}

              <HowToUse template={template} />

              <hr />

              {template.real_template_id && this.renderDescription()}

              <div className="video-container">
                <iframe
                  width="560"
                  height="315"
                  src="https://www.youtube.com/embed/u3E9HjuSfaM"
                  title="YouTube video player"
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                  cc_load_policy={language.startsWith('pt') ? '0' : '1'}
                ></iframe>
              </div>

              <ABNTNorms template={template} />

              {template.count > 0 && (
                <>
                  <br />
                  <p className="text-muted">
                    <UsageCount template={template} />
                  </p>
                </>
              )}
            </Col>
          </Row>
        </Panel>

        <Row>
          <Col md={12}>
            <Testimonials />
          </Col>
        </Row>

        <Row>
          <Col md={12}>
            <TwitterTestimonials />
          </Col>
        </Row>

        {template.emec_institution_id && (
          <>
            <Institution institutionId={template.emec_institution_id} />
            {/*<InstitutionCourses institutionId={this.props.template.emec_institution_id}/>*/}
          </>
        )}

        {template.is_abnt && template.is_book && (
          <Panel>
            <p>
              {i18n.t(
                'Já imaginou não se preocupar em como formatar cada um desses itens e deixar que o FastFormat faça tudo isso para você? Ao utilizar esse modelo você não vai se preocupar com regras ou normas de formatação de texto, referências e citações estabelecidas pela ABNT. O site formata automaticamente tudo para você, por exemplo',
              )}
            </p>

            <ol type="I">
              <li>
                {i18n.t(
                  'Elementos pré-textuais, como capa da monografia (ou capa de trabalho), folha de rosto (ou contra capa), sumário, lista de figuras, lista de tabelas, lista de siglas, etc.;',
                )}
              </li>
              <li>
                {i18n.t(
                  'Elementos textuais, como margens, parágrafo, paginação, citação da citação (também conhecido como "apud"), o uso do "et al", citação direta e citação indireta, citação de artigo, citação de livro, citação de site e todos os outros tipos de citações previstos pela norma da ABNT;',
                )}
              </li>
              <li>
                {i18n.t(
                  'Referências bibliográficas - referência de site, referência de artigo científico, referência de revista, referência de livro, dentre vários outros tipos.',
                )}
              </li>
            </ol>

            <p>
              {i18n.t(
                'Com o FastFormat você não precisa se preocupar com qualquer forma ou regra. Assim sobra tempo para focar o conteúdo da sua monografia, TCC, trabalho escolar ou acadêmico. Para isso você só precisa selecionar um dos modelos disponíveis no FastFormat, como modelos ABNT e até modelos específicos de faculdades, institutos, universidades, etc.',
              )}
            </p>
          </Panel>
        )}
      </>
    );
  }
}

TemplateDetail.propTypes = {
  template: PropTypes.object.isRequired,
};

export default withNamespaces()(TemplateDetail);
