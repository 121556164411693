import React from "react"
import PropTypes from "prop-types"
import { Link } from "react-router-dom"
import { withNamespaces } from "react-i18next";

class PaginationWithLink extends React.Component {
    constructor(props) {
        super(props);
    }

    getFirstLink() {
        return decodeURIComponent(
            this.props.basePath + '?' + this.props.pagination.links.first.split('?')[1]);
    }

    getPreviousLink() {
        return decodeURIComponent(
            this.props.basePath + '?' + this.props.pagination.links.previous.split('?')[1]);
    }

    getNextLink() {
        return decodeURIComponent(
            this.props.basePath + '?' + this.props.pagination.links.next.split('?')[1]);
    }

    getLastLink() {
        return decodeURIComponent(
            this.props.basePath + '?' + this.props.pagination.links.last.split('?')[1]);
    }

    getMiddleLink(url) {
        return decodeURIComponent(this.props.basePath + '?' + url.split('?')[1]);
    }

    render() {
        const { i18n, pagination } = this.props;

        return (
            <div>
                {pagination.totalPages > 1 &&
                    <nav style={{textAlign: 'center'}}>
                        <ul className="pagination">
                            {pagination.links.first && (
                                <li>
                                    <Link
                                        to={this.getFirstLink()}
                                        aria-label={i18n.t('Início')}
                                        title={i18n.t('Início')}
                                    >
                                        <span className="mdi mdi-chevron-left"/>
                                        <span className="mdi mdi-chevron-left"/>
                                    </Link>
                                </li>
                            )}

                            {pagination.pageNumber > 1 && (
                                <li>
                                    <Link to={this.getPreviousLink()} aria-label={i18n.t('Anterior')} role="button">
                                        <span className="mdi mdi-chevron-left"> </span>
                                    </Link>
                                </li>
                            )}

                            {pagination.links.middleLinks && (
                                pagination.links.middleLinks.map(item => (
                                    <li
                                        key={item.pageNumber}
                                        className={item.active ? 'active' : ''}
                                    >
                                        <Link
                                            to={this.getMiddleLink(item.link)}
                                            title={i18n.t('Página') + ' ' + item.pageNumber}
                                            aria-label={i18n.t('Página') + ' ' + item.pageNumber}
                                        >
                                            {item.pageNumber}
                                        </Link>
                                    </li>
                                ))
                            )}

                            {pagination.pageNumber < pagination.totalPages &&
                                <li>
                                    <Link to={this.getNextLink()} aria-label={i18n.t('Próximo')} role="button">
                                        <span className="mdi mdi-chevron-right"> </span>
                                    </Link>
                                </li>
                            }

                            {pagination.links.last && (
                                <li>
                                    <Link
                                        to={this.getLastLink()}
                                        aria-label={i18n.t('Último')}
                                        title={i18n.t('Último')}
                                    >
                                        <span className="mdi mdi-chevron-right"/>
                                        <span className="mdi mdi-chevron-right"/>
                                    </Link>
                                </li>
                            )}

                            <li>
                                <a style={{
                                    backgroundColor: 'unset',
                                    border: 'unset',
                                }}>
                                    <i className="text-muted text-center">
                                        {pagination.count} {i18n.t('resultados')}
                                    </i>
                                </a>
                            </li>
                        </ul>
                    </nav>}
            </div>
        )
    }
}

PaginationWithLink.propTypes = {
    pagination: PropTypes.object.isRequired,
    basePath: PropTypes.string,
};

PaginationWithLink.defaultProps = {
    pagination: {},
    basePath: '',
};

export default withNamespaces()(PaginationWithLink);