import React from 'react';
import { Link, Redirect } from 'react-router-dom';
import queryString from 'query-string';
import TemplateMini from './components/TemplateMini';
import Config from '../config';
import Ajax from '../common/ajax';
import Waiting from '../common/containers/Waiting';
import PaginationWithLink from '../common/components/PaginationWithLink';
import PropTypes from 'prop-types';
import PaginationWithClick from '../common/components/PaginationWithClick';
import SearchInput from '../common/components/SearchInput';
import TemplateDetail from './components/TemplateDetail';
import Row from '../common/containers/Row';
import Col from '../common/containers/Col';
import { withNamespaces } from 'react-i18next';
import TemplateRequest from './components/TemplateRequest';
import URLS from '../urls';
import ButtonDropdown from '../common/components/ButtonDropdown';
import LocaleUtils from '../common/LocaleUtils';

class TemplateList extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      processing: 0,
      templates: [],
      template: null,
      pagination: {},
      searchTerm: null,
      menuItems: [],
      submenuItems: [],
    };

    this.handleSearch = this.handleSearch.bind(this);
    this.fetchMenu = this.fetchMenu.bind(this);
    this.fetchTemplates = this.fetchTemplates.bind(this);
    this.fetchTemplate = this.fetchTemplate.bind(this);

    const { i18n } = props;

    this.vanueLabels = {
      generic: i18n.t('Genérico'),
      university: i18n.t('Universidades'),
      journal: i18n.t('Periódicos'),
      conference: i18n.t('Conferências'),
      legal: i18n.t('Jurídico'),
      other: i18n.t('Outros'),
      presentation: i18n.t('Slides'),
    };
  }

  componentDidMount() {
    this.fetchMenu();
    if (this.props.alias) {
      this.fetchTemplate(this.props.alias);
    } else {
      this.fetchTemplates();
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.alias && prevProps.alias !== this.props.alias) {
      this.fetchTemplate(this.props.alias);
    }
  }

  componentWillReceiveProps(nextProps) {
    this.fetchMenu();
    this.props = nextProps;
    if (!this.props.onTemplateClick && !nextProps.alias) {
      this.fetchTemplates();
    }
  }

  fetchMenu() {
    this.setState((state) => ({
      ...state,
      processing: state.processing + 1,
    }));
    const { venue, country } = queryString.parse(location.search);
    const url = `${Config.apiHost}templates/menu/?menu_item=${venue}&country=${country || ''}`;
    Ajax.get(url).done((menu) => {
      this.setState((state) => ({
        ...state,
        menuItems: menu.menu_items,
        submenuItems: menu.categories || [],
        processing: state.processing - 1,
      }));
    });
  }

  fetchTemplates(url) {
    if (!url) {
      url = `${Config.apiHost}templates/${location.search}`;
    }
    this.setState((state) => ({
      ...state,
      processing: state.processing + 1,
    }));

    Ajax.get(url).done((pagination) => {
      this.setState((state) => ({
        ...state,
        processing: state.processing - 1,
        pagination: pagination,
        templates: pagination.results,
        template: null,
        searchTerm: null,
      }));
      window.scrollTo(0, 0);
    });
  }

  fetchTemplate(alias) {
    const url = `${Config.apiHost}templates/?alias=${alias}`;
    this.setState((state) => ({
      ...state,
      processing: state.processing + 1,
    }));

    Ajax.get(url).done((pagination) => {
      this.setState((state) => ({
        ...state,
        processing: state.processing - 1,
        template: pagination.results[0],
        submenuItems: [],
      }));
      window.scrollTo(0, 0);
    });
  }

  handleSearch(searchTerm) {
    this.setState((state) => ({ ...state, searchTerm: searchTerm }));
    if (!this.props.useSearchRedirect) {
      const url = `${Config.apiHost}templates/?search=${searchTerm}`;
      this.fetchTemplates(url);
    }
  }

  renderTemplateItems() {
    const templates = [...this.state.templates];
    const { onTemplateClick } = this.props;

    let subArrays = [];

    while (templates.length > 0) {
      subArrays.push(templates.splice(0, 4));
    }

    return subArrays.map(function (templates, idx) {
      return (
        <Row key={idx}>
          {templates.map(function (template, idx) {
            return (
              <Col sm={4} md={3} key={idx}>
                <TemplateMini key={template.id} template={template} onClick={onTemplateClick} />
              </Col>
            );
          })}
        </Row>
      );
    });
  }

  render() {
    const { useSearchRedirect, onTemplateClick, i18n } = this.props;
    const { searchTerm, processing } = this.state;

    if (useSearchRedirect && searchTerm !== null) {
      return <Redirect to={`${URLS.modelos}?search=${searchTerm}`} />;
    }

    let { venue, category, country, search } = queryString.parse(location.search);

    const isUniversity = venue === 'university';

    const countries = {
      bra: { name: 'Brasil', lang: 'pt-BR' },
      bol: { name: 'Bolivia', lang: 'es-CO' },
      chl: { name: 'Chile', lang: 'es-CL' },
      col: { name: 'Colombia', lang: 'es-CO' },
      esp: { name: 'España', lang: 'es-ES' },
      per: { name: 'Perú', lang: 'es-PE' },
      prt: { name: 'Portugal', lang: 'pt-PT' },
      usa: { name: 'United States', lang: 'en-US' },
      ury: { name: 'Uruguay', lang: 'es-UY' },
    };

    if (isUniversity && !country) {
      Object.keys(countries).forEach((key) => {
        if (countries[key].lang === LocaleUtils.currentLocale()) {
          country = key;
        }
      });
    }

    window.document.title = `FastFormat - ${i18n.t('Modelos')}`;

    if (venue) {
      window.document.title = `${window.document.title} - ${this.vanueLabels[venue]}`;
    }

    return (
      <Waiting isProcessing={processing > 0}>
        <Row>
          <Col md={8}>
            <Link to={`${URLS.modelos}?venue=generic`}>
              <span className={`btn btn-lg btn-default ${venue === 'generic' ? 'active' : ''}`}>
                {this.vanueLabels.generic}
              </span>
            </Link>{' '}
            <ButtonDropdown
              label={`${this.vanueLabels.university} ${
                country && isUniversity ? ' (' + countries[country].name + ') ' : ''
              }`}
              size="lg"
              type="default"
              active={venue === 'university'}
            >
              {Object.keys(countries).map((key) => (
                <li className={country === key && isUniversity ? 'active' : ''} key={key}>
                  <Link to={`${URLS.modelos}?venue=university&country=${key}`}>{countries[key].name}</Link>
                </li>
              ))}
            </ButtonDropdown>{' '}
            <Link to={`${URLS.modelos}?venue=journal`}>
              <span className={`btn btn-lg btn-default ${venue === 'journal' ? 'active' : ''}`}>
                {this.vanueLabels.journal}
              </span>
            </Link>{' '}
            <Link to={`${URLS.modelos}?venue=conference`}>
              <span className={`btn btn-lg btn-default ${venue === 'conference' ? 'active' : ''}`}>
                {this.vanueLabels.conference}
              </span>
            </Link>{' '}
            <Link to={`${URLS.modelos}?venue=legal`}>
              <span className={`btn btn-lg btn-default ${venue === 'legal' ? 'active' : ''}`}>
                {this.vanueLabels.legal}
              </span>
            </Link>{' '}
            <Link to={`${URLS.modelos}?venue=other`}>
              <span className={`btn btn-lg btn-default ${venue === 'other' ? 'active' : ''}`}>
                {this.vanueLabels.other}
              </span>
            </Link>{' '}
            <Link to={`${URLS.modelos}?venue=presentation`}>
              <span className={`btn btn-lg btn-default ${venue === 'presentation' ? 'active' : ''}`}>
                {this.vanueLabels.presentation} <span className="label label-success">{i18n.t('novo')}</span>
              </span>
            </Link>
            {/*{this.state.menuItems.map(item => {*/}
            {/*return (*/}
            {/*<React.Fragment key={JSON.stringify(item)}>*/}
            {/*<Link to={`${URLS.modelos}?venue=${item[0]}`}>*/}
            {/*<span className={`btn btn-lg btn-default ${venue === item[0] ? 'active' : ''}`}>*/}
            {/*{item[1]}*/}
            {/*</span>*/}
            {/*</Link>*/}
            {/*{' '}*/}
            {/*</React.Fragment>*/}
            {/*);*/}
            {/*})}*/}
          </Col>
          <Col md={4}>
            <SearchInput
              handleSearch={this.handleSearch}
              value={search || ''}
              placeholder={i18n.t('Experimente "sao paulo"')}
            />
          </Col>
        </Row>

        <br />

        <Row>
          <Col md={12}>
            {this.state.submenuItems.map((item, idx) => {
              return (
                <React.Fragment key={idx}>
                  <Link to={`${URLS.modelos}?venue=${venue}&category=${item[0]}&country=${country || ''}`}>
                    <span
                      className={`btn btn-default btn-xs ${category === item[0] ? 'active' : ''}`}
                      style={{ marginBottom: '3px' }}
                    >
                      {item[1]}
                    </span>
                  </Link>{' '}
                </React.Fragment>
              );
            })}
          </Col>
        </Row>

        <br />

        {this.state.template && <TemplateDetail template={this.state.template} />}

        {this.state.templates && this.state.templates.length > 0 && (
          <React.Fragment>
            {this.state.template && <h2>{i18n.t('Outros modelos')}</h2>}

            <Row>
              <Col md={12}>{this.renderTemplateItems()}</Col>
            </Row>

            <Row>
              <Col md={12}>
                {!onTemplateClick && <PaginationWithLink pagination={this.state.pagination} basePath={URLS.modelos} />}

                {onTemplateClick && (
                  <PaginationWithClick handleClick={this.fetchTemplates} pagination={this.state.pagination} />
                )}
              </Col>
            </Row>
          </React.Fragment>
        )}

        <Row>
          <Col md={12}>
            <h4>
              <TemplateRequest />
            </h4>
          </Col>
        </Row>
      </Waiting>
    );
  }
}

TemplateList.propTypes = {
  /**
   * Search terms.
   */
  search: PropTypes.string,
  /**
   * A template alias.
   */
  alias: PropTypes.string,
  /**
   * Function to execute after clicking on a single template. If provided,
   * all router Links will be disabled, so this component can be reused
   * without navigating to other pages.
   *
   * Receives the template object as argument.
   */
  onTemplateClick: PropTypes.func,
  useSearchRedirect: PropTypes.bool,
};

TemplateList.defaultProps = {
  useSearchRedirect: true,
};

export default withNamespaces()(TemplateList);
