import React from 'react';
import PropTypes from 'prop-types';
import { withNamespaces } from 'react-i18next';

class HowToUse extends React.Component {
  render() {
    const { i18n, template } = this.props;
    const isPresentations = template.type === 'presentation';
    return (
      <>
        <h4 className="text-muted">
          <i className="mdi mdi-shield-security text-success" />{' '}
          {i18n.t('O FastFormat é seguro e confiável. Seus dados estão sempre bem protegidos.')}
        </h4>

        <h3>{i18n.t('Veja como é fácil utilizar')}</h3>

        <br />

        <ul className="user-timeline user-timeline-compact">
          <li className="latest">
            <div className="user-timeline-title">
              <b>{i18n.t('Passo')} 1</b> -{' '}
              {isPresentations ? i18n.t('Clique em Criar apresentação') : i18n.t('Clique em Criar documento')}
            </div>
          </li>
          <li>
            <div className="user-timeline-title">
              <b>{i18n.t('Passo')} 2</b> - {i18n.t('Escreva seu texto ou importe de um arquivo Word')}
            </div>
          </li>
          <li>
            <div className="user-timeline-title">
              <b>{i18n.t('Passo')} 3</b> -{' '}
              {isPresentations
                ? i18n.t('Visualize e edite sua apresentação')
                : i18n.t('Visualize e edite seu documento')}
            </div>
          </li>
          <li>
            <div className="user-timeline-title">
              <b>{i18n.t('Passo')} 4</b> -{' '}
              {isPresentations
                ? i18n.t('Faça download da apresentação formatada')
                : i18n.t('Faça download do documento formatado')}
            </div>
          </li>
        </ul>

        <h4 className="text-muted">
          <i className="mdi mdi-time text-success" /> {i18n.t('Tudo isso em menos de 5 minutos')}
        </h4>
      </>
    );
  }
}

HowToUse.propTypes = {
  template: PropTypes.object.isRequired,
};

export default withNamespaces()(HowToUse);
