import React from 'react';
import PropTypes from 'prop-types';
import Config from '../../config';
import { Link } from 'react-router-dom';
import URLS from '../../urls';
import LazyImage from '../../common/components/LazyImage';
import { withNamespaces } from 'react-i18next';
import UsageCount from './UsageCount';

class TemplateMini extends React.Component {
  renderTitle() {
    const { i18n } = this.props;
    const currentLang = i18n.language === 'pt-br' ? 'pt' : i18n.language;

    if (this.props.onClick) {
      return (
        <a role="button" onClick={() => this.props.onClick(this.props.template)}>
          {this.props.template.name}
        </a>
      );
    } else {
      return (
        <Link to={`${currentLang === 'pt' ? URLS.modelos : URLS.templates}${this.props.template.alias}`}>
          {this.props.template.name}
        </Link>
      );
    }
  }

  renderThumbnail() {
    const { template, onClick, i18n } = this.props;
    const currentLang = i18n.language === 'pt-br' ? 'pt' : i18n.language;

    const thumbURL = `${Config.djangoHost}static/${template.thumbnail}?${template.thumbnail_hash}`;
    const image = (
      <div className="thumbnail">
        <LazyImage src={thumbURL} alt={template.name} title={template.name} use43Ratio={template.is_presentation} />
      </div>
    );

    if (onClick) {
      return (
        <div role="button" onClick={() => onClick(template)}>
          {image}
        </div>
      );
    } else {
      return <Link to={`${currentLang === 'pt' ? URLS.modelos : URLS.templates}${template.alias}`}>{image}</Link>;
    }
  }

  renderForPresentation() {
    const { template } = this.props;
    return (
      <>
        <div className="panel">
          <div className="panel-heading" style={{ paddingBottom: 0 }}>
            {this.renderThumbnail()}
          </div>
          <div className="panel-body" style={{ paddingTop: 0 }}>
            {!template.is_presentation && (
              <>
                <span style={{ fontSize: '12pt' }}>{this.renderTitle()}</span>
                <br />
              </>
            )}

            <span className="text-muted" style={{ whiteSpace: 'nowrap' }}>
              {template.category}
            </span>

            {template.count > 0 && (
              <>
                <br />
                <small className="text-muted">
                  <UsageCount template={template} />
                </small>
              </>
            )}
          </div>
        </div>
      </>
    );
  }

  render() {
    const { template } = this.props;

    if (template.type === 'presentation') {
      return this.renderForPresentation();
    }

    return (
      <div className="panel">
        <div className="panel-heading">
          {this.renderTitle()}
          <small className="text-muted" style={{ whiteSpace: 'nowrap' }}>
            <br />
            {template.category}
          </small>
          {template.count > 0 && (
            <>
              <br />
              <small className="text-muted">
                <UsageCount template={template} />
              </small>
            </>
          )}
        </div>
        <div className="panel-body">{this.renderThumbnail()}</div>
      </div>
    );
  }
}

TemplateMini.propTypes = {
  /**
   * The template object.
   */
  template: PropTypes.object.isRequired,
  /**
   * If provided, the template Link will be replaced by this action.
   */
  onClick: PropTypes.func,
};

export default withNamespaces()(TemplateMini);
