import React from "react"
import PropTypes from "prop-types"
import Ajax from "../../common/ajax";
import Config from "../../config";
import Waiting from "../../common/containers/Waiting";
import Row from "../../common/containers/Row";
import Col from "../../common/containers/Col";
import Panel from "../../common/containers/Panel";
import {withNamespaces} from "react-i18next";

class Institution extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            institution: {},
            isProcessing: false
        }
    }

    componentDidMount() {
        this.fetchInstitution();
    }

    fetchInstitution() {
        this.setState({
            ...this.state,
            isProcessing: true
        });
        Ajax.get(`${Config.apiHost}institutions/${this.props.institutionId}`)
            .done( (institution) => {
                this.setState({
                    ...this.state,
                    institution: institution,
                    isProcessing: false
                });
            });
    }

    render() {
        const {i18n} = this.props;
        return (
            <Waiting isProcessing={this.state.isProcessing}>
                <Panel title={`${i18n.t('Mais sobre a')} ${this.state.institution.acronym}`}>
                    <Row>
                        <Col md={6}>
                            <table className="table table-condensed">
                                <tbody>
                                    <tr>
                                        <td><label>{i18n.t('Nome')}</label></td>
                                        <td>{this.state.institution.name}</td>
                                    </tr>
                                    <tr>
                                        <td><label>{i18n.t('Sigla')}</label></td>
                                        <td>{this.state.institution.acronym}</td>
                                    </tr>
                                    <tr>
                                        <td nowrap="nowrap">
                                            <label>{i18n.t('Organização acadêmica')}</label>
                                        </td>
                                        <td>
                                            {this.state.institution.organization}
                                            ({this.state.institution.administrative_category})
                                        </td>
                                    </tr>
                                    {this.state.institution.ci && this.state.institution.ci !== '-' &&
                                    <tr>
                                        <td><label>{i18n.t('Conceito institucional')}</label>
                                        </td>
                                        <td>{this.state.institution.ci}</td>
                                    </tr>}
                                    {this.state.institution.ci_ead && this.state.institution.ci_ead !== '-' &&
                                    <tr>
                                        <td><label>{i18n.t('Conceito institucional (EaD)')}</label></td>
                                        <td>{this.state.institution.ci_ead}</td>
                                    </tr>}
                                    {this.state.institution.igc && this.state.institution.igc !== '-' &&
                                    <tr>
                                        <td><label>{i18n.t('Índice geral de cursos')}</label>
                                        </td>
                                        <td>{this.state.institution.igc}</td>
                                    </tr>}
                                    <tr>
                                        <td>
                                            <label>{i18n.t('Endereço')}</label>
                                        </td>
                                        <td>
                                            <address>
                                                {this.state.institution.address},
                                                {this.state.institution.city}
                                                ({this.state.institution.uf})
                                            </address>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </Col>
                        <Col md={6}>
                            {this.state.institution.maintainer &&
                            <table className="table table-condensed">
                                <thead>
                                <tr>
                                    <th colSpan="2">{i18n.t('Instituição mantenedora')}</th>
                                </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td><label>{i18n.t('Nome')}</label></td>
                                        <td>{this.state.institution.maintainer.name}</td>
                                    </tr>
                                    <tr>
                                        <td><label>{i18n.t('CNPJ')}</label></td>
                                        <td>{this.state.institution.maintainer.cnpj}</td>
                                    </tr>
                                    <tr>
                                        <td><label>{i18n.t('Natureza jurídica')}</label></td>
                                        <td>{this.state.institution.maintainer.legal_nature}</td>
                                    </tr>
                                </tbody>
                            </table>}
                        </Col>
                    </Row>
                </Panel>
            </Waiting>
        )
    }
}

Institution.propTypes = {
    institutionId: PropTypes.number.isRequired,
};

export default withNamespaces()(Institution);